.investment-in-progress-detail-card {
  background-color: var(--dark-bg);
  border: 1px solid var(--bip-capital-cream);
  border-radius: 16px;
  padding: 0.5rem 0.75rem;
  color: var(--bip-capital-cream);
  display: flex;
  flex-direction: column;

  .investment-in-progress-detail-card-header {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    flex-wrap: wrap;
  }

  .enrollment-status {
    display: flex;
    justify-content: flex-start;
    min-width: fit-content;

    .badge-status {
      background-color: rgba(60, 179, 113, 0.2);
      color: #3cb371;
      padding: 6px 12px;
      border-radius: 999px;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      box-shadow: 0 0 6px rgba(60, 179, 113, 0.6);
      transition: box-shadow 0.3s, background-color 0.3s;

      &:hover {
        background-color: rgba(60, 179, 113, 0.3);
        box-shadow: 0 0 10px rgba(60, 179, 113, 0.8);
      }
    }
  }

  .investment-in-progress-detail-card-body {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;;

    h5 {
      margin: 0;
      font-weight: bold;
    }

  }

  .investment-in-progress-detail-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;


    .progress-section {
      flex: 1;
      
      .completion-text {
        font-size: 0.85rem;
      }

      .investment-in-progress-detail-card-progress-bar {
        width: 100%;
        background-color: #ccc;
        height: 8px;
        border-radius: 4px;
        margin-bottom: 0.5rem;

        .progress-bar {
          background-color: var(--bip-capital-light-green);
        }
      }
    }
  }
}
