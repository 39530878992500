.bip-capital-cc-layout-wrapper {
  display: flex;
  height: 100vh;

  .row {
    --bs-gutter-y: 1rem;
  }

  .toast {
    position: absolute;
    top: 0;
    right: 0;
    color: black;
    z-index: 999;
  }

  a {
    color: var(--bip-capital-cream);
  }
}

.bip-capital-cc-layout-main {
  overflow: auto;
  flex: 1;
  padding: 0 2rem;
}