:root {
  --dark-bg: #2f363d;
  --medium-bg: #3a4048;
  --primary-color: #0d9b62;
  --primary-hover-color: #0b7a51;
  --text-color: #ffffff;
  --border-color: #2f363d;
  --button-yellow: #FFC107;
  --button-yellow-hover: #e0a800;
}

.opportunity-detail-page {
  background-color: var(--bip-capital-background);
  padding: 2rem;
  border-radius: 12px;

  .opportunity-header {
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: var(--dark-bg);
    border-radius: 12px;

    .opportunity-logo {
      width: 60px;
      height: 60px;
      border: 1px solid var(--border-color);
      border-radius: 25%;
      object-fit: contain;
    }

    h1 {
      font-size: 1.8rem;
      font-weight: bold;
      margin-left: 0.75rem;
      margin-top: 0.75rem;
      color: var(--bip-capital-cream);
    }

    .opportunity-video {
      margin: 2rem 0;
      border-radius: 12px;
      overflow: hidden;

      video {
        border-radius: 12px;
        width: 100%;
        height: 350px;
        object-fit: cover;
      }
    }

    .investment-info {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .investment-text {
        margin-bottom: 1rem;

        p {
          margin: 0.3rem 0;
          color: var(--bip-capital-cream);
        }

        .investment-value {
          font-size: 1.3rem;
          font-weight: bold;
          color: var(--bip-capital-light-yellow);
        }
      }

      .invest-button {
        background-color: var(--button-yellow);
        border: none;
        padding: 0.8rem 2rem;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 8px;
        transition: background-color 0.3s ease;
        color: #000;

        &:hover {
          background-color: var(--button-yellow-hover);
        }
        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }
        &:active {
          background-color: var(--button-yellow-hover) !important;
          outline: none !important;
          box-shadow: none !important;
        }
      }
    }

    .about-investment {
      background-color: var(--medium-bg);
      padding: 1.5rem;
      border-radius: 12px;
      margin-top: 2rem;

      h4 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
        color: var(--bip-capital-cream);
      }

      p {
        font-size: 1rem;
        line-height: 1.5;
        color: var(--bip-capital-cream);
      }
    }
  }

  .documents-card {
    margin-bottom: 0.5rem; 
  }
  
}