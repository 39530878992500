.bip-capital-cash-flow-detail {
  .card {
    border: solid 1px var(--bip-capital-cream);
    color: var(--bip-capital-white);

    .card-title {
      h4, h6 {
        margin-bottom: 0.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      h4 {
        font-weight: bold;
      }

      svg {
        width: 1.4em;
        margin-right: 0.5rem;
      }
    }
  }
}