.bip-capital-cc-dashboard {
  padding: 0px 1rem 1rem 1rem;

  h5 {
    font-weight: 600;
  }
}

.bip-capital-cc-news-section {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(315px, 1fr) );
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;

  >:first-child {
    grid-row: 1 / 3;
  }
}
