.bip-capital-important-documents {
  &.card {
    max-height: 13em;
    color: var(--bip-capital-cream);
    
    &.expanded {
      max-height: initial;
    }
  }

  ul {
    list-style: none;
    padding: 1em 0 0 0;
    margin-top: -1em;

    li {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    li:not(:last-child)  {
      padding-bottom: 1rem;
    }

    .fa-file-pdf {
      color: var(--bip-capital-cream);
      margin-right: 0.6rem;
    }
  }


  .card-footer {
    display: flex;
    border-top: 0;
    background-color: #0000;
    align-items: center;

    span {
      cursor: pointer;

      .fa-caret-down {
        margin-left: 0.5rem;
        font-size: 1.2rem;
        transition: transform 225ms ease-in-out;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
}