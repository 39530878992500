.bip-capital-cc-user-avatar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;

  button {
    color: #ffffff;
    cursor: pointer;
    border: none;
    background: none;
  }

  i {
    margin-left: 0.5rem;
    font-size: 1.5em;
  }
}

.bip-capital-cc-user-avatar-popover {
  border-radius: 0.5rem;

  .popover-body {
    padding-bottom: 0;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
}