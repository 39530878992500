html {
  --bip-capital-cream: #F7F9F4;
  --bip-capital-background: #1C252C;
  --bip-capital-card-background: #ffffff29;
  --bip-capital-white: #ffffff;
  --bip-capital-light-yellow: #F9E4B4;
  --bip-capital-light-green: #3cb371;
}

body.bip-capital-cc-body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Note: 
    May want to consider theming bootstrap via Sass according to the official guide, 
    rather than adding a class to body to up the selector specificity as I've done here
  */
  background-color: var(--bip-capital-background);
  color: white;

  .card {
    background-color: var(--bip-capital-card-background);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bip-capital-select-wrapper {
  position: relative;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-image: url('./assets/chevron-down.svg');
    background-size: 16px 12px;
  }

  select, select:focus {
    color: white;
    padding-right: 2rem;
    background-color: var(--bip-capital-background);
    border-color: var(--bip-capital-cream);
    box-shadow: none;
    cursor: pointer;
  }
}

.react-datepicker__input-container {
  > input {
    appearance: none;
    color: white;
    background-color: var(--bip-capital-background);
    border: 1px solid var(--bip-capital-cream);
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    background-clip: padding-box;

    &:focus-visible {
      outline: unset;
    }
  }
} 

ol.breadcrumb {
  background-color: inherit;
  color: white;
  a {
    color: inherit;
  }
  .breadcrumb-item.active {
    color: inherit;
  }

  .breadcrumb-item+.breadcrumb-item::before {
    font-family: "Font Awesome 5 Free";
    content: "\f054";
    color: inherit;
    font-weight: 900;
    font-size: 0.9em;
    line-height: 1.8em;
  }
}

.bip-capital-page-header {
  position: relative;
  bottom: 1em; 
  display: inline
}

.modal-dialog {
  .modal-content {
    background-color: var(--bip-capital-background);;
  }
  
  button.close {
    color: white;
    opacity: 1;
  }
  a {
    color: var(--bip-capital-cream);
  }
} 
  
.btn.btn-link.bip-capital-link {
  color: var(--bip-capital-cream);
}

.bip-capital-icon-button {
  border: none;
  background: none;
  color: white;
}