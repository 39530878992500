.Primary-button {
  display: inline-block;
  align-self: center;
  width: 100%;
  border-radius: 6px;
  background-color: #F7F9F4;
  font-family: sofia-pro, sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #171f27;
  border: none;
  padding: 12px;
}

.Primary-button-disabled {
  display: inline-block;
  align-self: center;
  width: 100%;
  border-radius: 6px;
  background-color: #595959;
  font-family: sofia-pro, sans-serif;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #171f27;
  border: none;
  padding: 12px;
}
