.investment-card {
  background-color: #2f363d;
  border: 1px solid #f7f9f4;
  border-radius: 16px;
  padding: 20px;
  color: #f7f9f4;
  margin: 0 10px;
  display: flex;
  flex-direction: column;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 8px;

    .active-enrollments {
      font-size: 0.9rem;
      color: #f7f9f4;
    }

    .view-detail {
      color: #ffa500;
      font-weight: bold;
      cursor: pointer;
      padding: 4px 8px;
      border-radius: 4px;
      transition: background-color 0.3s;
      text-decoration: none;

      &:hover {
        background-color: rgba(255, 165, 0, 0.15);
      }
    }
  }

  .enrollment-status {
    display: flex;
    justify-content: flex-start;
    padding: 0 20px;
    margin-bottom: 15px;

    .badge-status {
      background-color: rgba(60, 179, 113, 0.2);
      color: #3cb371;
      padding: 6px 12px;
      border-radius: 999px;
      font-size: 0.95rem;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      box-shadow: 0 0 6px rgba(60, 179, 113, 0.6);
      transition: box-shadow 0.3s, background-color 0.3s;

      &:hover {
        background-color: rgba(60, 179, 113, 0.3);
        box-shadow: 0 0 10px rgba(60, 179, 113, 0.8);
      }
    }
  }

  .card-body {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 0 20px;

    .investment-logo {
      width: 70px;
      height: 70px;
      object-fit: contain;
      border-radius: 10px;
      margin-right: 20px;
    }

    .investment-details {
      h5 {
        margin: 0;
        color: #fff;
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 4px;
      }
      p {
        margin: 0;
        color: #f7f9f4;
        font-size: 0.95rem;
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .commitment-amount {
      font-weight: bold;
      font-size: 1.2rem;
      margin-bottom: 8px;
    }

    .progress-section {
      .completion-text {
        font-size: 0.85rem;
        text-align: right;
      }

      .custom-progress-bar {
        width: 100%;
        background-color: #ccc;
        height: 8px;
        border-radius: 4px;

        .progress-bar {
          background-color: #3cb371;
        }
      }
    }
  }
}
