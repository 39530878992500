.bip-capital-symposium-page {
  padding-bottom: 1rem;

  .video-grid {
    --thumbnail-width: 350px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax((var(--thumbnail-width)), 1fr));
    grid-gap: 1rem;
  }

  @media (max-width: 1400px) {
    .video-grid {
      --thumbnail-width: 450px;
    }
  }

  @media (max-width: 600px) {
    .video-grid {
      --thumbnail-width: 100%;
    }
  }
}

