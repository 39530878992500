.bip-capital-cc-overview-card {
  border: solid 1px var(--bip-capital-cream);
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 14%);
  background-color: rgba(255, 255, 255, 0.08);
  padding: 1rem;
}

.bip-capital-cc-overview-card label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.bip-capital-cc-overview-card div {
  font-size: 2em;
  color: var(--bip-capital-cream);
}
