.bip-capital-video-card {
  width: (var(--thumbnail-width));
  padding: 0.5rem;
  background-color: var(--bip-capital-card-background);
  border-radius: 0.25rem;
}

.bip-capital-video-thumbnail-wrapper {
  position: relative;

  img.thumbnail {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: contain;
  }

  .play-button {
    position: absolute;
    width: 75px;
    height: 75px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    filter: invert(1);
    opacity: 0.5;
    background: transparent;
    border:none;
    transition: opacity 0.3s;

    &:hover {
      opacity: 1;
    }
  }
}

.video-description {
  p {
    font-size: 0.75em;
  }
}
