.bip-capital-event-detail-page {
  .sign-up-button {
    background-color: var(--bip-capital-cream);
    padding-left: 3rem;
    padding-right: 3rem;
    font-weight: bold;
    white-space: nowrap;
  }
}

@media (min-width: 992px) {
  .bip-capital-event-detail-page {
    margin: 0 10rem 0 10rem;
  }
}

