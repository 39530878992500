.bip-capital-expandable-paragraph {
  margin-bottom: 0;

  .expandable-content {
    font-size: 14px;
    max-width: 100%;
    overflow: hidden;
  }

  .view-more {
    color: var(--bip-capital-cream);
    text-decoration: underline;
    cursor: pointer;
  }
}