.bip-capital-news-detail {
  .news-header-column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .news-header {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .news-subheader {
      display: inline-flex;
      font-size: 12px;

      .news-chip {
        border-radius: 14px;
        background-color: #434343;
        padding: 2px 12px;
      }
    }

    img {
      height: 124px;
      width: 124px;
      object-fit: cover;
      margin-right: 1rem;
    }
  }

}