.bip-capital-cc-chip { 
  color: #fff;
  background-color: inherit;
  padding: 5px 14px 5px 18px;
  border-radius: 18px;
  font-weight: 600;
  padding: 5px 14px 5px 18px;
  outline: none;
  border: none;

  &.active-chip {
    background-color: var(--bip-capital-light-yellow);;
    color: #000000;
  }
}