.bip-capital-cc-datatable-wrapper {
  .table {
    >:not(:first-child) {
      border-top-width: 0;
    }

    thead th {
      border-bottom-width: 0;
    }
  }

  tfoot.bip-capital-cc-datatable-footer {
    font-weight: bold;
    border: 1px solid #2c3034;

    td {
      border-bottom-width: 0px;
    }
  }

  .pagination {
    justify-content: flex-end;
    margin-bottom: 0;

    .page-link {
      border: solid 1px #F7F9F4;
      color: var(--bip-capital-cream);
      background-color: var(--bip-capital-background);
    }

    .page-item.active .page-link  {
      background-color: var(--bip-capital-light-yellow);
      border-color: var(--bip-capital-light-yellow);
    }
  }
}

.bip-capital-cc-datatable-header {
  border: 1px solid #2c3034;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.bip-capital-datatable-grouped {
  thead.bip-capital-datatable-header > tr > th, td:not(.bip-capital-datatable-group-header) {
    padding-left: 4rem;
  }

  td.bip-capital-datatable-group-header {
    cursor: pointer;
    font-weight: bold;
    padding-left: 1rem;
    user-select: none;
    font-size: 1.15em;

    .fa-caret-right {
      margin-right: 0.5rem;

      &.expanded {
        transform: rotate(90deg);
      }
    }
  }
  
}
