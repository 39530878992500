.Text-input {
  display: flex;
  width: 95%;
  border-radius: 2px;
  border: solid 1px #aab9a6;
  background-color: #ffffff;
  padding: 10px 8px;
  margin: 4px 0 0;
}

.Text-input:disabled {
  background-color: var(--bip-capital-card-background);
  color: rgb(255 255 255 / 75%);
}
