.investment-carousel-container {
  background-color: #2f363d;
  border: 1px solid #f7f9f4;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  position: relative;

  .carousel-header {
    font-size: 1.5rem;
    color: #f7f9f4;
    margin-bottom: 15px;
    padding: 0 10px;
  }

  .navigation-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0;
  }
}
