:root {
  --bip-capital-cream: #f0f0e1;
  --bip-capital-light-yellow: #f1e5ac;
  --error-color: #d9534f;
  --primary-color: #0d9b62;
  --primary-hover-color: #085b3c;
  --disabled-color: #808080;
  --border-color-focus: #0b7a51;
}

@mixin form-control-style {
  border: 1px solid var(--bip-capital-cream);
  color: black;
  height: 3rem;
  border-radius: 8px;
  font-size: 1rem;
  padding: 0.85rem;
  margin-bottom: 1rem;
}

@mixin hover-focus-style {
  color: black;
  border-color: var(--border-color-focus);
  box-shadow: none;
  outline: none;
}

.modal-body {
  padding: 2.5rem 2rem;

  label {
    font-weight: 600;
    color: var(--bip-capital-cream);
    font-size: 1.15rem;
    margin-bottom: 0.6rem;
  }

  .form-control {
    @include form-control-style;

    &:disabled {
      background-color: rgba(255, 255, 255, 0.15);
      color: var(--bip-capital-cream);
    }


    &:focus,
    &:hover,
    &:active {
      @include hover-focus-style;
    }

    &.is-invalid {
      border-color: var(--error-color);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active,
    &:-moz-autofill,
    &:-moz-autofill:hover,
    &:-moz-autofill:focus,
    &:-moz-autofill:active {
      background-color: rgba(255, 255, 255, 0.15);
      color: var(--bip-capital-cream);
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: var(--bip-capital-cream);
      -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.15) inset;
    }
    option:disabled {
      background-color: #555555;
      color: #cccccc; 
      cursor: not-allowed;
    }
  
    option:not(:disabled):hover {
      background-color: var(--primary-hover-color);
      color: white;
    }
  }

  input.form-control {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select.form-control {
    padding-right: 2rem;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 8px 10px;

    &:focus {
      border-color: var(--border-color-focus);
      box-shadow: 0 0 5px rgba(11, 122, 81, 0.5);
    }
  }

  .text-muted {
    color: var(--bip-capital-light-yellow);
  }

  .alert-danger {
    background-color: #521616;
    color: var(--bip-capital-cream);
    border-radius: 6px;
    padding: 0.75rem;
    animation: fadeIn 0.5s ease-in-out;
  }
}

.modal-footer {
  justify-content: center;

  .indicate-interest-btn {
    background-color: var(--primary-color);
    border-radius: 50px;
    padding: 0.85rem 2.5rem;
    font-weight: bold;
    border: none;
    transition: background-color 0.3s ease;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--primary-hover-color);
      outline: none;
      box-shadow: none;
    }

    &:disabled {
      background-color: var(--disabled-color);
      color: white;
      cursor: not-allowed;
      opacity: 1;
    }
  }
}