.bip-capital-opportunities-page {
  .top-right-alert {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1050;
    max-width: 90vw;
  }

  .opportunities-card-wrapper {
    background-color: var(--bip-capital-background);
    padding: 2rem;
    border-radius: 10px;
  }

  .opportunity-card-link {
    text-decoration: none;
  }

  .opportunity-card {
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 10px;
    border: 1px solid var(--bip-capital-background);
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: translateY(-4px);
    }

    .opportunity-image {
      width: 100px;
      height: 100px;
      object-fit: contain;
    }

    .opportunity-title {
      font-size: 1.3rem;
      font-weight: 700;
      color: var(--bip-capital-cream);
      margin-bottom: 1rem;
    }

    .opportunity-details {
      font-size: 0.8rem;
      color: var(--bip-capital-light-yellow);

      i {
        margin-right: 0.3rem;
      }

      .fa-calendar-alt {
        margin-left: 0.5rem;
        margin-right: 0.2rem;
      }

    }

    .opportunity-description {
      font-size: 0.9rem;
      color: var(--bip-capital-cream);
    }
  }

  .bip-webinar-section {
    padding: 0 0 1rem 0;
    background-color: var(--bip-capital-background);
    margin-left: 1rem;
    border-radius: 10px;

    .webinar-title {
      font-size: 1.2rem;
      color: var(--bip-capital-cream);
      margin-bottom: 1rem;
    }

    .webinar-card {
      background-color: rgba(255, 255, 255, 0.08);
      border-radius: 10px;
      padding: 1rem;
      margin-bottom: 1rem;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: translateY(-4px);
      }

      .webinar-image {
        width: 80px;
        height: 80px;
        object-fit: contain;
      }

      .webinar-link-title {
        font-size: 1rem;
        color: var(--bip-capital-cream);
      }

      .webinar-time {
        font-size: 1rem;
        color: var(--bip-capital-light-yellow);
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center;

    .page-item.active .page-link {
      background-color: var(--bip-capital-light-yellow);
      color: var(--bip-capital-background);
    }

    .page-link {
      color: var(--bip-capital-cream);
      background-color: var(--bip-capital-background);
      border: 1px solid var(--bip-capital-cream);

      &:hover {
        background-color: var(--bip-capital-light-yellow);
        color: #000;
      }
    }
  }

  .pagination-text {
    text-align: center;
    margin-top: 1rem;
    font-size: 0.9rem;
    color: var(--bip-capital-cream);
  }
}