.Two-factor-authentication {
  font-family: sofia-pro, sans-serif;
  font-size: calc(13px + 2vmin);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
}

.MFA-btn-container {
  padding-top: 30px;
}
