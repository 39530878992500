
.unauthenticate-wrapper {
  background-image: url('../../../assets/login-background.png');
  height: 100vh;
  object-fit: contain;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.unauthenticate-wrapper .bip-capital-container {
  padding-top: 0;
  height: 100%;
}

.unauthenticate-wrapper .Logo {
  margin-top: -2rem;
  margin-bottom: 0rem;
}

@media screen and (max-width: 850px) {
  .unauthenticate-wrapper {
    background-size: cover;
  }
}
.Log-in {
  font-family: sofia-pro, sans-serif;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 0;
}

.Sign-up {
  display: flex;
  flex-direction: row;
}

.Sign-up-text {
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  color: #ffffff;
  margin: 0;
}

.Forgot-password {
  display: flex;
  flex-direction: row;
  padding: 1em 0;
}

.Forgot-password-text {
  font-family: sofia-pro, sans-serif;
  text-decoration: underline;
  color: #ffffff;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  color: #ffffff;
}

.Forgot-password-text > a {
  color: inherit;
}

.terms-of-use a {
  color: #DDF1FF;
}
