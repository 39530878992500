.shareholders-meeting {
  .side-icon {
    color: white;
    background-color: currentColor;
    height: 20px;
    mask: url('../../assets/easel.svg') no-repeat;
    -webkit-mask: url('../../assets/easel.svg') no-repeat;
    mask-size: contain;
    -webkit-mask-size: contain;
    mask-position: 2px center;
    -webkit-mask-position: 2px center;
  }
}

.bip-capital-cc-sidenav.pro-sidebar {
  .item-content {
    white-space: unset;
  }
  // Overiding the default 3rd party component original width of 270px
  width: 220px;
  min-width: 230px; 

  .head-div {
    padding: 1rem 0.75rem;
    align-items: center;
  }

  .icon-suffix:hover {
    color: #F9E4B4;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }

  .fa-chevron-left::before {
    font-size: 1.2em;
  }

  .toggled .fa-chevron-left::before {
    font-size: 1em;
    content: "\f0c9";
  }
}

.bip-capital-cc-sidenav-logo {
  width: 100%;
  padding-right: 0.25rem;
}

.bip-capital-cc-sidenav-item div::before {
  content: "";
  position: absolute;
  background-color: #F9E4B4;
  width: 100%;
  height: 2px;
  bottom: -3px;
  left: 0;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  transition: all 0.25s ease-in-out 0s;
}

.bip-capital-cc-sidenav-item-active div::before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.bip-capital-cc-sidenav-item:hover .side-icon {
  color: #F9E4B4;
}