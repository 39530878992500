.arrow-button {
  background-color: #444;
  border: none;
  color: #f7f9f4;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
  margin: 5px 0;

  &:hover {
    color: #F9E4B4;
    background-color: #555;
    box-shadow: 0 0 8px #F9E4B4;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
  }

  &.prev {
    margin-right: 10px;
  }

  &.next {
    margin-left: 10px;
  }
}
