.bip-capital-cc-event-card {
  .card {
    border: solid 1px #ffffff0a;
    color: var(--bip-capital-white);
  }

  .card-title {
    color: var(--bip-capital-cream);
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  h6 {
    font-weight: 600;
    margin-bottom: 0;
  }
}