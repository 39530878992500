.bip-capital-cc-card-stack {
  display: flex;
  flex-direction: column;

  > :not(:first-child) .card {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  > :not(:last-child) .card {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}