.bip-capital-cc-cash-flow-card {
  .card {
    border: solid 1px var(--bip-capital-cream);
    color: var(--bip-capital-white);
  }

  .card-title {
    color: var(--bip-capital-cream);
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
  }

  h6 {
    font-weight: 600;
    margin-bottom: 0;
  }
}

.bip-capital-cc-cash-flow-card-names {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  color: #9d9d9d;
  font-size: 0.9em;;

  span:first-of-type {
    font-weight: bold;
  }
}