.bip-capital-event-detail {
  .card {
    border: solid 1px #6a6a6a;
    color: var(--bip-capital-white);

    .card-title {
      h6, h4 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }

      h4 {
        font-weight: bold;
      }

      .fa-calendar-alt {
        color: var(--bip-capital-cream);
        margin-right: 0.9rem;
      }
    }
  }

  .detail-signup-button {
    border-radius: 2px;
    background-color: var(--bip-capital-cream);
    color: #fff;
    font-size: 12px;
  }

  .event-card-description {
    overflow-y: hidden;
    max-height: 6em;
  }
}
