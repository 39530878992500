section.bip-capital-cc-investments-news {
  h5 > a {
    font-size: 0.75em;
    margin-left: 0.5rem;
  }

  .bip-capital-cc-news-card:not(:only-of-type) {
    margin-bottom: 1rem;
  }
}


.bip-capital-events {
  .bip-capital-selection-filter-group {
    padding-left: 0;
  }
}

.bip-capital-events-filter-controls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  
  > * {
    width: 100%;
    
    .react-datepicker__input-container > input {
      width: 100%;
    }
  }
}

@media (min-width: 992px) {
  .bip-capital-events-filter-controls > * {
    flex: 0 0 auto;
    width: auto;

    &:not(:last-child):not(.react-datepicker__tab-loop) {
      margin-right: 1rem;
    }
  }
}