.bip-capital-news-page-card {
  display: flex;
  
  img {
    height: 124px;
    width: 124px;
    object-fit: cover;
  }
  
  .news-info {
    flex: 1;
    font-size: 14px;
    
    .news-date-line {
      display: inline-flex;
      flex-direction: column;
      
      .news-chip {
        border-radius: 14px;
        background-color: #434343;
        padding: 2px 12px;
      }
    }

    .news-update {
      overflow-y: hidden;
      max-height: 44px;
    }
  }

  a.more-link {
    text-decoration: none;
    
    .fa-chevron-right {
      margin-left: 0.25rem;
      font-size: 0.9em;
    }
  }
}

@media (min-width: 992px) {
  .bip-capital-news-page-card {
    .news-info .news-date-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
