.bip-capital-selection-filter-group {
  padding: 0.5rem;
  
  > *:not(:first-child) {
    margin-left: 1rem;
  }
  
  .bip-capital-select-wrapper {
    margin-bottom: 0.5rem;
  }
}