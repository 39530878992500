.bip-capital-cc-news-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  width: 100%;
  padding: 2.5rem 2rem 1rem 1rem;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.14);
  border-radius: 0.25rem;
  background-size: cover;
}