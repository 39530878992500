.bip-capital-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 110px;
}

@media screen and (max-width: 50em) {
  .bip-capital-container {
    padding-top: 110px;
  }
}
