.bip-capital-shareholders-page {
  padding-bottom: 1rem;

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shareholders-image {
    width: 400px;
    overflow: visible;
  }

  .content-container {
    padding: 4rem;
    padding-top: 2rem;
  }
}

