section.bip-capital-cc-investments-news {
  h5 > span {
    color: var(--bip-capital-cream),;
    font-size: 0.75em;
    margin-left: 0.5rem;
  }

  .bip-capital-cc-news-card:not(:only-of-type) {
    margin-bottom: 1rem;
    
  }
}