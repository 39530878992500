.password-input-wrapper {
  position: relative;
}

.password-input-wrapper .password-toggle {
  position: absolute;
  font-size: 16px;
  top: 50%;
  margin-top: -1em;
  height: 2em;
  width: 2em;
  right: 1.3em;
  cursor: pointer;
  border: none;
  background: none;
}

.password-input-wrapper .Text-input {
  padding-right: 2em;
}