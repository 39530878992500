.settings-wrapper .bip-capital-container {
  padding-top: 50px;
}

.settings-wrapper .Rectangle {
  padding: 0;
}

.Settings-title-container {
  background-color: #32393f;
  height: 141px;
}

.Settings-title {
  font-family: sofia-pro, sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  padding: 60px 60px 0px 60px;
}

.settings-content-container {
  padding: 10px 61px 35px;
}

.Settings-label {
  font-family: sofia-pro, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;
  margin: 1rem 0 0.5rem 0;
}

.Settings-link {
  display: block;
  font-family: sofia-pro, sans-serif;
  font-size: 16px;
  text-decoration: underline;
  color: #F9E4B4;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

hr {
  background-color: #32393f;
}
