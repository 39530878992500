.bip-capital-dashboard-pie-chart-card {
  border: solid 1px var(--bip-capital-cream);
  border-radius: 6px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 14%);
  background-color: rgba(255, 255, 255, 0.08);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  
  .bip-capital-dashboard-pie-chart-header {
    p {
      font-size: 14px;
    }
    
    h5 {
      color: var(--bip-capital-cream);
      font-size: 18px;
    }
  }

  
  .bip-capital-dashboard-pie-chart-wrapper {
    height: 250px;
  }

  .bip-capital-dashboard-piechart-label {
    font-size: 0.75rem;
  }
}