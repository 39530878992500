.bip-capital-investment-detail {
  display: flex;
  flex-wrap: wrap;

  .bip-capital-investment-description {
    flex: 2 0 100px;
    min-width: 400px;
    padding-right: 4rem;
  }
  
  .card {
    flex: 1 0 100px;
    min-width: 200px;
  }
  
  h4 {
    font-weight: bold;
  }
}